<script>
import { dateFormating } from '../../../../../util'
import { DATE_DEFAULT_REGXP } from '../../../../../util/const'
import { SLOT_TYPE } from '../../slots/core/slots-conts'
export default {
  
  render() {
    return this.$scopedSlots.default({
      checkDataToLoadList: this.checkDataToLoadList
    })
  },

  props: {
    examDate: String,
    examLocation: String
  },

  methods: {
    checkDataToLoadList() {
      if(this.examLocation && this.examDate) 
        this.$emit('change', {
          query: {
            location: this.examLocation,
            exam_date_before: dateFormating(this.examDate).join('-'),
            exam_date_after: dateFormating(this.examDate).join('-'),
            page: 1,
            size: 500,
            type: SLOT_TYPE.TOP
          }
        })
    },


  },

  watch: {
    examDate(val) {
      if(DATE_DEFAULT_REGXP.test(val))
        this.checkDataToLoadList()
    },

    examLocation(val) {
      this.checkDataToLoadList()
    }
  }
}
</script>